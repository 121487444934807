<template>
  <v-card flat tile :loading="loading">
    <v-card-title>{{ $t("page.translation.edit.title") }}</v-card-title>
    <v-card-subtitle>{{
      $t("page.translation.edit.subtitle")
    }}</v-card-subtitle>
    <v-card-text>
      <TranslationGroupForm
        v-if="translation"
        :initial="translation"
        :errors="errors"
        :loading="loading"
        @submit="save"
        ref="form"
        :isEdit="true"
      />
    </v-card-text>
    <v-card-actions class="justify-end">
      <Button
        @click="save"
        color="success"
        v-text="$t('dialog.translation.updateLabel')"
      />
      <Button
        text
        @click="$emit('close')"
        v-text="$t('dialog.closeDialogLabel')"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import TranslationGroupForm from '@/components/Form/TranslationGroup'
import TranslationGroupService from '@/services/translation-group'

export default {
  name: 'view-edit-translation-group',
  components: {
    TranslationGroupForm,
  },
  props: {
    translationId: {
      type: Number,
    }
  },
  data: () => ({
    loading: false,
    translation: null,
    errors:null,
  }),
  mounted() {
    this.fetchTranslation(this.translationId)
  },
  methods: {
    async fetchTranslation(id) {
      this.loading = true
      TranslationGroupService.get(this.translationId)
        .finally(() => this.loading = false)
        .then(res => this.translation = res.data)
    },
    save() {
      if (this.$refs.form.validate()) {
        const t = this.$refs.form.translation
        TranslationGroupService.update(this.translation.id, t)
          .finally(() => this.loading = false)
          .then(() => this.$refs.form.clear())
          .then(() => this.$emit('updated'))
          .then(() => this.showSuccess(this.$t('message.updateTranslationSuccess')))
          .catch(errors => this.errors = errors.response.data)
      }
    },
  },
  data: () => ({
    loading: false,
    errors: null,
    translation: null,
  }),
  watch: {
    translationId(val) {
      this.fetchTranslation(val)
    }
  }
}
</script>
