import ApiService from './base'

export default {
  endpoint: '/api/applications',

  query(params?: any) {
    return ApiService.query(this.endpoint, {
      params: params
    })
  },
  syncApps(actives) {
    return ApiService.post(`${this.endpoint}/sync`, actives)
  }
}
