export default {
  loginSuccess: 'Login successful, welcome!',
  logoutSuccess: 'Logout successful, goodbye!',
  loginFail: 'There was a problem to Login your user.',
  pageRefreshed: 'Refreshed',
  pageRefreshFailed: 'Refresh failed',
  languageChanged: 'Language changed successfully',
  deleteTranslationSuccess: 'Delete successful',
  updateTranslationSuccess: 'Translation updated',
  googleTranslateSuccess: 'Text was successfully translated',
  appSyncSuccess: 'Applications successfully updated',
  sqliteUpdateSuccess: 'Sqlite Database successfully updated',
  exportTranslationSuccess: 'Export successful',
  importSuccess: 'File imported successfully'
}
