import ApiService from './base'

export default {
  endpoint: '/api/translation-groups',

  query(params?: any) {
    return ApiService.query(this.endpoint, {
      params: params
    })
  },
  update(id: number, translation: Record<string, any>) {
    translation._method = 'PATCH'
    return ApiService.post(`${this.endpoint}/${id}`, translation)
  },
  get(id: number) {
    return ApiService.get(`${this.endpoint}/${id}`)
  },
  create(translation: Record<string, any>) {
    return ApiService.post(this.endpoint, translation)
  },
  delete(id: number) {
    return ApiService.delete(`${this.endpoint}/${id}`)
  },
  getCategories() {
    return ApiService.get(`${this.endpoint}/categories`)
  },
  getGroups(category) {
    return ApiService.get(`${this.endpoint}/categories/${category}/groups`)
  },
  export(data) {
    return ApiService.download(`${this.endpoint}/export`, data, "POST")
  },
  massDelete(data) {
    return ApiService.post(`${this.endpoint}/mass-delete`, data)
  },
}

