var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{staticClass:"translations-table",attrs:{"headers":_vm.header,"items":_vm.translations,"search":_vm.search,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.numTotal,"show-select":""},on:{"update:search":function($event){_vm.search=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","lg":"4"}},[_c('TextField',{attrs:{"prepend-inner-icon":"mdi-magnify","clearable":"","label":_vm.translationSearchLabel,"solo":"","hide-details":"","flat":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"3","lg":"3"}},[_c('LanguageSelector',{attrs:{"compact":""},model:{value:(_vm.langFilter),callback:function ($$v) {_vm.langFilter=$$v},expression:"langFilter"}})],1),_c('v-col',{attrs:{"cols":"3","lg":"3"}},[_c('ApplicationSelector',{attrs:{"compact":""},model:{value:(_vm.appFilter),callback:function ($$v) {_vm.appFilter=$$v},expression:"appFilter"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2","lg":"2"}},[_c('TooltipButton',{attrs:{"icon":"","plain":"","color":"success","tooltip":_vm.$t('table.translation.createTooltip')},on:{"click":function($event){return _vm.$emit('translationCreate')}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'success darken-2' : 'success'}},[_vm._v("mdi-plus-thick")])]}}])})],1),_c('TooltipButton',{attrs:{"icon":"","plain":"","color":"secondary","tooltip":_vm.$t('table.refreshTooltip')},on:{"click":_vm.refresh}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'orange darken-2' : 'orange'}},[_vm._v("mdi-refresh")])]}}])})],1)],1)],1)],1)],1),_c('v-slide-y-transition',[_c('MassOperations',{staticClass:"ml-2",on:{"export":_vm.exportSelected,"delete":_vm.deleteTranslations},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]},proxy:true},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.formatDateTimeStr(item.updated_at) || '')}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"orange darken-3"},on:{"click":function($event){return _vm.editTranslation(item)}}},on),[_vm._v("mdi-pen")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('table.translation.editTranslationTooltip'))}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"red darken-3"},on:{"click":function($event){return _vm.deleteTranslation(item)}}},on),[_vm._v("mdi-trash-can")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('table.translation.deleteTranslationTooltip'))}})])]}},{key:"footer.prepend",fn:function(){return [_c('MassOperations',{on:{"export":_vm.exportSelected,"delete":_vm.deleteTranslations},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }