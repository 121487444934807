import app from './app'
import form from './form'
import table from './table'
import page from './page'
import message from './message'
import dialog from './dialog'
import ui from './ui'

export default {
  app,
  form,
  message,
  table,
  page,
  dialog,
  ui,

  sidebar: {
    welcome: 'Welcome, {name}!',
    translations: 'Translations',
    'translation-groups': 'Translation Groups',
    'update-applications': 'Update Applications',
    'update-sqlite': 'Update Sqlite Database',
    import: 'Import',
  },
}
