<template>
  <v-form
    ref="form"
    @submit.prevent="onSubmit"
    :errors="errors"
    v-model="valid"
  >
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <FileInput
            dense
            :label="uploadFieldLabel"
            v-model="uploadFile"
            :rules="rules.required"
            @change="onFileInput"
          />

          <v-scroll-y-transition>
            <v-col cols="12" v-if="errors">
              <v-card outlined>
                <v-card-title>
                  <v-icon color="error" left>mdi-alert</v-icon>
                  {{ $t("form.import.errors.title") }}
                </v-card-title>
                <v-card-subtitle v-text="$t('form.import.errors.subtitle')" />
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-left"
                          v-text="$t('form.import.errors.idRow')"
                        />
                        <th
                          class="text-left"
                          v-text="$t('form.import.errors.descRow')"
                        />
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="errorRow in Object.keys(errors)">
                        <tr
                          v-for="(errorMsg, idx) in errors[errorRow].errors"
                          :key="`error-${errorRow}-${idx}`"
                        >
                          <td v-text="errorRow" />
                          <td v-text="$t(errorMsg)" />
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/form'

export default {
  name: 'import-form',
  mixins: [FormMixin],
  components: {},
  data: () => ({
    uploadFile: null,
  }),
  mounted() {

  },
  methods: {
    clear() {
      this.uploadFile = null
      this.errors = null
    },
    onFileInput(file) {
      this.uploadFile = file
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.translation)
      }
    },
  },
  computed: {
    uploadFieldLabel() {
      return this.$t('form.import.uploadFieldLabel')
    },
  },

}
</script>
