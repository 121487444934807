<template>
  <v-navigation-drawer
    :permanent="breakpoint && show"
    v-model="show"
    :width="width"
    hide-overlay
    color="primary"
    :expand-on-hover="false"
    mini-variant-width="70"
    dark
    app
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          <v-icon left large class="pb-3">mdi-car-outline</v-icon>

          <router-link
            :to="translationsRoute"
            v-text="title"
            class="white--text text-decoration-none"
          />
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t("sidebar.welcome", { name: `${currentUser.name}` }) }}<br />
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list flat dense nav class="pb-0">
      <v-list-item :to="translationsRoute">
        <v-list-item-icon>
          <v-icon v-text="`mdi-view-dashboard`" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="this.$t('sidebar.translations')" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list flat dense nav class="pb-0">
      <v-list-item :to="translationGroupsRoute">
        <v-list-item-icon>
          <v-icon v-text="`mdi-group`" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="this.$t('sidebar.translation-groups')" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list flat dense nav class="pb-0">
      <v-list-item :to="applicationsRoute">
        <v-list-item-icon>
          <v-icon v-text="`mdi-archive-refresh`" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="this.$t('sidebar.update-applications')" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list flat dense nav class="pb-0">
      <v-list-item @click="sqliteDialog = true">
        <v-list-item-icon>
          <v-icon v-text="`mdi-update`" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="this.$t('sidebar.update-sqlite')" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list flat dense nav class="pb-0">
      <v-list-item @click="importDialog = true">
        <v-list-item-icon>
          <v-icon v-text="`mdi-database-import`" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="this.$t('sidebar.import')" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-container v-if="$route.meta.sidebar">
      <v-card dense>
        <component :is="$route.meta.sidebar" />
      </v-card>
    </v-container>

    <v-dialog max-width="600" v-model="importDialog">
      <ImportPage @close="importDialog = false" />
    </v-dialog>

    <v-dialog max-width="600" v-model="sqliteDialog">
      <SqlitePage @close="sqliteDialog = false" />
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import Mainbus from "@/mainbus";
import { mapGetters } from "vuex";
import ImportPage from "@/views/Import";
import SqlitePage from "@/views/sqliteDialog";

export default {
  name: "app-sidebar",
  components: {
    ImportPage,
    SqlitePage,
  },
  data: () => ({
    show: true,
    width: 250,
    importDialog: false,
    sqliteDialog: false,
  }),
  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
  computed: {
    ...mapGetters(["currentUser", "isUser"]),
    translationsRoute() {
      return { name: "translations" };
    },
    translationGroupsRoute() {
      return { name: "translation-groups" };
    },
    applicationsRoute() {
      return { name: "applications" };
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
    breakpoint() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>
