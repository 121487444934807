export default {
  closeDialogLabel: 'Close',
  translation: {
    createLabel: 'Create',
    updateLabel: 'Update'
  },
  translationGroups: {
    createLabel: 'Create',
    updateLabel: 'Update'
  },
  import: {
    title: 'Import translations',
    subtitle: 'Upload the translation file below',
    importLabel: 'Import'
  },
  sqliteUpdate: {
    title: 'Update Sqlite Database',
    subtitle: 'Please confirm that you want to proceed',
    importLabel: 'Update'
  }
}
