export default {
  languageSelector: {
    label: 'Language filter',
    others: 'others',
    selectAll: 'Select all'
  },
  appSelector: {
    label: 'App filter',
    others: 'others',
    selectAll: 'Select all'
  }
}
