<template>
  <v-form
    ref="form"
    @submit.prevent="onSubmit"
    :errors="errors"
    v-model="valid"
  >
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-container>
      <v-row dense>
        <v-col cols="6">
          <v-combobox
            :label="categoryLabel"
            :items="categories"
            v-model="translation.category"
            prepend-inner-icon="mdi-sitemap"
            :rules="rules.required"
            :disabled="isEdit"
        /></v-col>
        <v-col cols="6">
          <v-combobox
            :label="groupLabel"
            :items="groups"
            v-model="translation.group"
            prepend-inner-icon="mdi-group"
            :rules="rules.required"
            :disabled="isEdit"
        /></v-col>
        <v-col cols="12">
          <TextField
            :label="keyLabel"
            v-model="translation.key"
            :rules="rules.required"
            :disabled="isEdit"
        /></v-col>
        <v-col cols="6" v-for="lang in languages" :key="`lang-${lang.lang}`">
          <TextField :label="lang.name" v-model="translation.lang[lang.lang]">
            <template v-slot:prepend-inner>
              <country-flag :country="getFlagCode(lang.lang)" size="normal" />
            </template>
            <template v-slot:append>
              <TooltipButton
                icon
                plain
                color="success"
                @click="googleTranslate(lang.lang)"
                :tooltip="$t('page.translation.translateTooltip')"
              >
                <v-hover v-slot="{ hover }">
                  <v-icon :color="hover ? 'blue darken-2' : 'blue'"
                    >mdi-google-translate</v-icon
                  >
                </v-hover>
              </TooltipButton>
            </template>
          </TextField></v-col
        >
        <v-col cols="12">
          <ApplicationSelector
            v-model="translation.applications"
            :dense="false"
            :hideDetails="false"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/form'
import FlagsMixin from '@/mixins/flags'
import LanguageService from '@/services/languages'
import TranslationService from '@/services/translation'
import TranslationGroupService from '@/services/translation-group'
import ApplicationService from '@/services/applications'
import ApplicationSelector from '@/components/Ui/ApplicationSelector'

export default {
  name: 'translation-create-form',
  mixins: [FormMixin, FlagsMixin],
  components: { ApplicationSelector },
  props: ['initCategory', 'initGroup', 'isEdit'],
  data: () => ({
    translation: {
      category: null,
      group: null,
      key: null,
      lang: {},
      applications: [],
    },

    applications: [],
    languages: [],
    categories: [],
    groups: [],
  }),
  mounted() {
    this.fetchApplications()
    this.fetchLanguages()
      .then(() => this.translation = this.initial ? this.prepareTranslation(this.initial) : this.translation)
    this.fetchCategories()

    if (this.initCategory) {
      this.translation.category = this.initCategory
    }
    if (this.initGroup) {
      this.translation.group = this.initGroup
    }
  },
  methods: {
    clear() {
      this.translation = {
        category: null,
        group: null,
        key: null,
        lang: {},
        applications: [],
      }
    },
    async fetchCategories() {
      return TranslationGroupService.getCategories()
        .then(res => this.categories = res.data)
    },
    async fetchGroups(category) {
      return TranslationGroupService.getGroups(category)
        .then(res => this.groups = res.data)
    },
    async googleTranslate(lang) {
      let key = this.translation.lang[lang]
      if (!key) {
        this.showError(this.$t('message.noStringToTranslate'))
      }
      TranslationService.googleTranslate(key, lang)
        .then(res => this.translation.lang = Object.assign({}, this.translation.lang, res.data))
        .then(() => this.showSuccess(this.$t('message.googleTranslateSuccess')))
        .catch(() => this.showError(this.$t('message.googleTranslateError')))
    },
    async fetchApplications() {
      return ApplicationService.query()
        .then(response => this.applications = response.data)
        .catch(error => console.log(error))
    },
    async fetchLanguages() {
      return LanguageService.query()
        .then(response => this.languages = response.data)
        .catch(error => console.log(error))
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.translation)
      }
    },
    prepareTranslation(t) {
      let translation = {
        category: t.category,
        group: t.group,
        key: t.key,
        lang: {},
        applications: [],
      }
      this.languages.forEach(lang => {
        if (t[lang.lang]) {
          translation.lang[lang.lang] = t[lang.lang]
        }
        translation.applications = t.applications
      })
      return translation
    },
  },
  computed: {
    applicationsLabel() {
      return this.$t('form.translation.applicationsLabel')
    },
    keyLabel() {
      return this.$t('form.translation.keyLabel')
    },
    categoryLabel() {
      return this.$t('form.translationGroup.categoryLabel')
    },
    groupLabel() {
      return this.$t('form.translationGroup.groupLabel')
    },
    indexLabel() {
      return this.$t('form.translationGroup.indexLabel')
    },
  },
  watch: {
    initial(value) {
      this.translation = value ? this.prepareTranslation(value) : this.translation;
    },
    'translation.category'(val) {
      this.fetchGroups(val)
    },
    initCategory(v) {
      this.translation.category = v
    },
    initGroup(v) {
      this.translation.group = v
    }
  },
}
</script>
