import ApiService from './base'

export default {
  endpoint: '/api/languages',

  query(params?: any) {
    return ApiService.query(this.endpoint, {
      params: params
    })
  },
}
