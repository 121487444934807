export default {
  methods: {
    // call in promise => .then(response => this.forceFileDownload(...))
    forceFileDownload(response: any, filename = "file", type = 'application/pdf', ext = 'pdf') {
      var newBlob = new Blob([response.data], { type })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)
        return
      }

      const data = window.URL.createObjectURL(newBlob)
      var link = document.createElement('a')
      link.href = data
      link.download = filename + "." + ext
      link.click()

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  }
}
