<template>
  <v-form
    ref="form"
    :loading="loading"
    @submit.prevent="onSubmit"
    @keyup.enter="onSubmit"
    v-model="valid"
  >
    <v-slide-y-transition>
      <ErrorAlert
        v-if="authErrors"
        :errors="authErrors"
        :message="authErrors.message"
      />
    </v-slide-y-transition>

    <v-container>
      <v-row dense>
        <v-col cols="12">
          <TextField
            prepend-icon="mdi-email"
            v-model="email"
            :rules="rules.email"
            :label="emailLabel"
          />
        </v-col>

        <v-col cols="12">
          <TextField
            v-model="password"
            prepend-icon="mdi-key"
            :rules="rules.password"
            :label="passwordLabel"
            type="password"
          />
        </v-col>

        <v-col cols="12">
          <Button color="success" type="submit" class="mr-4" block>{{
            $t("form.login.loginButton")
          }}</Button>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { LOGIN } from '@/store/actions.type'

export default {
  name: 'login-form',
  data: () => ({
    loading: false,
    valid: true,
    email: '',
    password: '',
  }),
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$store
          .dispatch(LOGIN, {
            email: this.email,
            password: this.password,
            i18n: this.$i18n,
            vuetify: this.$vuetify
          })
          .finally(() => this.loading = false)
          .then(() => this.$router.push({ name: 'translations' }))
          .then(() => this.errors = null)
          .then(() => this.showSuccess(this.$root.$t('message.loginSuccess')))
          .catch(() => this.showError(this.$root.$t('message.loginFail')))
      }
    },
  },
  computed: {
    ...mapGetters(['isAdmin', 'authErrors']),
    rules() {
      return {
        email: [
          v => !!v || this.$t('login.email.empty'),
          v => /.+@.+\..+/.test(v) || this.$t('login.email.invalid')
        ],
        password: [v => !!v || this.$t('login.password.empty')]
      }
    },
    emailLabel() {
      return this.$t('form.login.emailLabel')
    },
    passwordLabel() {
      return this.$t('form.login.passwordLabel')
    }
  }
}
</script>
