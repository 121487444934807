import Cookies from 'js-cookie'

const ID_TOKEN_KEY = process.env.VUE_APP_AUTH_COOKIE_NAME || 'id_token'
const DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN

export const getToken = (): any => {
    return Cookies.get(ID_TOKEN_KEY)
};

export const saveToken = (token: string) => {
    Cookies.set(ID_TOKEN_KEY, token, {
        secure: true,
        expires: 1,
        path: '/',
        domain: DOMAIN
    })
};

export const destroyToken = () => {
    Cookies.remove(ID_TOKEN_KEY, {
        secure: true,
        path: '/',
        domain: DOMAIN
    })
};
export default { getToken, saveToken, destroyToken };
