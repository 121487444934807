import ApiService from './base'

export default {
  endpoint: '/api/sqlite',

  query(params?: any) {
    return ApiService.query(this.endpoint, {
      params: params
    })
  },
  update(actives) {
    return ApiService.post(`${this.endpoint}/update`, [])
  }
}
