<template>
  <v-app>
    <Sidebar v-if="isAuthenticated" ref="sidebar" />
    <Appbar v-if="isAuthenticated" @toggleSidebar="toggleSidebar" />

    <v-main>
      <v-fade-transition>
        <router-view />
      </v-fade-transition>
    </v-main>

    <Footer />
    <Notification ref="notifier" />
  </v-app>
</template>

<script>
import Mainbus from './mainbus'
import { mapGetters } from 'vuex'

import Sidebar from '@/layout/Sidebar'
import Appbar from '@/layout/Appbar'
import Footer from '@/layout/Footer'
import Notification from '@/layout/Notification'

export default {
  name: 'app',
  components: {
    Sidebar,
    Appbar,
    Footer,
    Notification,
  },
  methods: {
    toggleSidebar() {
      this.$refs.sidebar.toggle()
    },
  },
  created() {
    Mainbus.$on('message', data => this.$refs.notifier.onMessage(data))
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
}
</script>
