<template>
  <ViewContainer class="fill-height">
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <v-img
          justify="center"
          src="@/assets/huesges_logo.png"
          class="mb-6"
          max-width="300"
        />
        <v-card max-width="600">
          <v-card-text>
            <LoginForm />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </ViewContainer>
</template>

<script>

import LoginForm from '@/components/Form/Login'

export default {
  name: 'app-login',
  components: {
    LoginForm
  },
}
</script>
