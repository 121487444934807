<template>
  <v-container v-bind="$attrs" v-on="$listeners" fluid>
    <slot />
  </v-container>
</template>

<script>
export default {
  name: 'view-container',
}
</script>
