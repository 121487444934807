<template>
  <SelectField
    v-bind="$attrs"
    v-on="$listeners"
    v-model="appFilter"
    :items="apps"
    :label="appFilterLabel"
    prepend-inner-icon="mdi-application"
    multiple
    :solo="solo"
    :hide-details="hideDetails"
    :flat="flat"
    outlined
    :dense="dense"
    item-text="name"
    item-value="id"
    @change="onChange"
  >
    <template
      v-slot:selection="{ item, index }"
      v-if="compact && appFilter.length > 2"
    >
      <Chip v-if="index === 0" dense>
        <span>{{ item.name }}</span>
      </Chip>
      <span v-if="index === 1" class="grey--text text-caption">
        (+{{ appFilter.length - 1 }} {{ $t("ui.appSelector.others") }})
      </span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item ripple @click="selectAllApps">
        <v-list-item-action>
          <v-icon :color="appFilter.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="$t('ui.appSelector.selectAll')" />
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </SelectField>
</template>

<script>
import ApplicationService from '@/services/applications'

export default {
  name: 'application-selector',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    compact: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    appFilter: [],
    apps: []
  }),
  mounted() {
    this.appFilter = this.value
    this.fetchApplications()
  },
  methods: {
    selectAllApps() {
      this.$nextTick(() => {
        if (this.allAppsSelected) {
          this.appFilter = []
        } else {
          this.appFilter = this.apps.map(e => e.id).slice()
        }
        this.$emit('input', this.appFilter)
      })
    },
    onChange() {
      this.$emit('input', this.appFilter)
    },
    async fetchApplications() {
      return ApplicationService.query()
        .then(response => this.apps = response.data)
        .catch(error => console.log(error))
    },
  },
  computed: {
    appFilterLabel() {
      return this.$t('ui.appSelector.label')
    },
    allAppsSelected() {
      return this.appFilter.length == this.apps.length
    },
    icon() {
      if (this.allAppsSelected) return 'mdi-close-box'
      if (this.appFilter.length > 0) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    value(v) {
      this.appFilter = v
    }
  }
}
</script>
