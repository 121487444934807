<template>
  <Table
    :headers="header"
    :items="translations"
    :search.sync="search"
    :loading="loading"
    :options.sync="options"
    :server-items-length="numTotal"
    class="translations-table"
    show-select
    v-model="selected"
  >
    <template v-slot:top>
      <v-toolbar flat dense>
        <v-container class="ma-0 pa-0" fluid>
          <v-row>
            <v-col cols="4" lg="4">
              <TextField
                prepend-inner-icon="mdi-magnify"
                clearable
                v-model="search"
                :label="translationSearchLabel"
                solo
                hide-details
                flat
                outlined
                dense
              />
            </v-col>
            <v-col cols="3" lg="3">
              <LanguageSelector v-model="langFilter" compact />
            </v-col>
            <v-col cols="3" lg="3">
              <ApplicationSelector v-model="appFilter" compact />
            </v-col>
            <v-col cols="2" lg="2" class="text-right">
              <TooltipButton
                icon
                plain
                color="success"
                @click="$emit('translationCreate')"
                :tooltip="$t('table.translation.createTooltip')"
              >
                <v-hover v-slot="{ hover }">
                  <v-icon :color="hover ? 'success darken-2' : 'success'"
                    >mdi-plus-thick</v-icon
                  >
                </v-hover>
              </TooltipButton>
              <TooltipButton
                icon
                plain
                color="secondary"
                @click="refresh"
                :tooltip="$t('table.refreshTooltip')"
              >
                <v-hover v-slot="{ hover }">
                  <v-icon :color="hover ? 'orange darken-2' : 'orange'"
                    >mdi-refresh</v-icon
                  >
                </v-hover>
              </TooltipButton>
            </v-col>
          </v-row></v-container
        >
      </v-toolbar>
      <v-slide-y-transition>
        <MassOperations
          class="ml-2"
          v-model="selected"
          @export="exportSelected"
          @delete="deleteTranslations"
        />
      </v-slide-y-transition>
    </template>
    <template v-slot:item.updated_at="{ item }">
      <span v-text="formatDateTimeStr(item.updated_at) || ''" />
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            color="orange darken-3"
            class="mr-2"
            @click="editTranslation(item)"
            v-on="on"
            >mdi-pen</v-icon
          >
        </template>
        <span v-text="$t('table.translation.editTranslationTooltip')" />
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            color="red darken-3"
            class="mr-2"
            @click="deleteTranslation(item)"
            v-on="on"
            >mdi-trash-can</v-icon
          >
        </template>
        <span v-text="$t('table.translation.deleteTranslationTooltip')" />
      </v-tooltip>
    </template>
    <template #footer.prepend>
      <MassOperations
        v-model="selected"
        @export="exportSelected"
        @delete="deleteTranslations"
      />
    </template>
  </Table>
</template>

<script>
import { mapGetters } from 'vuex'
import Mainbus from '@/mainbus'
import TableMixin from '@/mixins/table'
import DownloadMixin from '@/mixins/download'
import TranslationService from '@/services/translation'
import LanguageSelector from '@/components/Ui/LanguageSelector'
import ApplicationSelector from '@/components/Ui/ApplicationSelector'
import MassOperations from '@/components/Table/MassOperations'

export default {
  name: 'table-translations',
  mixins: [TableMixin, DownloadMixin],
  components: { LanguageSelector, ApplicationSelector, MassOperations },
  data: () => ({
    selected: [],
    search: '',
    searchStr: '',
    numMinChars: 3,
    appFilter: [],
    langFilter: [{ lang: 'en', name: 'English' }],
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    translations: [],

    applications: [],
    languages: []
  }),
  mounted() {
    Mainbus.$on('refresh', () => this.refresh())
  },
  methods: {
    exportSelected() {
      console.log("exporting selected ", this.selected)
      TranslationService.export({ export: this.selected.map(e => e.id) })
        .then(response => this.forceFileDownload(response, "translations", 'text/plain', 'csv'))
        .then(() => this.selected = [])
        .then(() => this.showSuccess(this.$t('message.exportTranslationSuccess')))
    },
    deleteTranslation(t) {
      if (confirm(this.$t('table.translation.deleteConfirm', t.field))) {
        TranslationService.delete(t.id)
          .then(() => this.showSuccess(this.$t('message.deleteTranslationSuccess')))
          .then(() => this.fetchTranslations())
      }
    },
    deleteTranslations() {
      if (confirm(this.$t('table.translation.massDeleteConfirm'))) {
        TranslationService.massDelete({ delete: this.selected.map(e => e.id) })
          .then(() => this.showSuccess(this.$t('message.massDeleteTranslationSuccess')))
          .then(() => this.selected = [])
          .then(() => this.fetchTranslations())
      }
    },
    refresh() {
      this.fetchTranslations()
    },
    editTranslation(t) {
      this.$emit('editTranslation', t)
    },
    async fetchTranslations() {
      this.loading = true
      await TranslationService.query(this.tableParams)
        .finally(() => this.loading = false)
        .then(response => {
          this.translations = response.data.data
          this.numTotal = response.data.total
        })
        .catch(error => console.log(error))
    }
  },
  computed: {
    ...mapGetters(['currentUser']),

    currentUserId() {
      return this.currentUser?.id
    },
    translationSearchLabel() {
      return this.$t('table.translation.translationSearchLabel')
    },

    tableParams() {
      return {
        paginate: true,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        limit: this.options.itemsPerPage,
        sort: this.options.sortBy ? this.options.sortBy[0] : null,
        sortDesc: this.options.sortDesc ? this.options.sortDesc[0] : null,
        search: this.searchStr,
        apps: this.appFilter
      }
    },
    header() {
      let header = [
        {
          text: this.$t('table.translation.key'),
          align: 'left',
          sortable: true,
          value: 'field',
        },
      ]

      this.langFilter.forEach(lang => header.push({
        text: lang.name,
        align: 'left',
        sortable: false,
        value: lang.lang,
      }))

      header.push(
        {
          text: this.$t('table.translation.updated_at'),
          align: 'center',
          sortable: true,
          value: 'updated_at',
        })

      header.push(
        {
          text: this.$t('table.translation.actions'),
          align: 'center',
          sortable: false,
          value: 'action',
        })
      return header
    },
  },
  watch: {
    search(val) {
      if(val == ""){
        this.searchStr = this.search
      }
      if (val && val.length >= this.numMinChars) {
        clearTimeout(this._searchTimerId)
        this._searchTimerId = setTimeout(() => {
          this.searchStr = this.search
          this.options.page = 1
        }, 500) /* 500ms throttle */
      }
    },
    '$route'() {
      this.fetchTranslations()
    },
    tableParams: {
      async handler() {
        this.cl = setTimeout(this.fetchTranslations, 400)
      },
      deep: true,
    },
  }
}
</script>

<style>
.translations-table tbody tr {
  cursor: pointer;
}
</style>
