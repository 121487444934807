<template>
  <v-toolbar flat dense class="ml-0 pl-0">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <Button
          @click="$emit('delete', value)"
          :disabled="value.length == 0"
          v-on="on"
          color="error"
          class="mr-2"
          depressed
          small
        >
          <v-icon left>mdi-trash-can</v-icon>
          {{ $t("table.massDelete") }}
        </Button>
      </template>
      <span v-text="$t('table.massDeleteTranslationTooltip')" />
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <Button
          depressed
          @click="$emit('export', value)"
          :disabled="value.length == 0"
          v-on="on"
          color="success"
          small
        >
          <v-icon left>mdi-database-export</v-icon>
          {{ $t("table.massExport") }}
        </Button>
      </template>
      <span v-text="$t('table.massDatabaseExportTooltip')" />
    </v-tooltip>
  </v-toolbar>
</template>

<script>
export default {
  name: 'table-mass-operation-toolbar',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
}
</script>
