<template>
  <v-toolbar flat dense>
    <v-container fluid class="ma-0 pa-0">
      <v-row>
        <v-col>
          <Autocomplete
            v-model="category"
            :items="categories"
            :label="categoryFilterLabel"
            prepend-inner-icon="mdi-sitemap"
            solo
            flat
            dense
            outlined
            hide-details
            clearable
          />
        </v-col>
        <v-col>
          <Autocomplete
            v-model="group"
            :items="groups"
            :label="groupFilterLabel"
            prepend-inner-icon="mdi-group"
            solo
            flat
            dense
            outlined
            hide-details
            clearable
          />
        </v-col>
        <v-col>
          <SelectField
            v-model="keyType"
            :items="keyTypes"
            :label="keyFilterLabel"
            item-text="text"
            item-value="key"
            prepend-inner-icon="mdi-filter"
            solo
            flat
            dense
            outlined
            hide-details
            clearable
          />
        </v-col>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<script>
import TranslationGroupService from '@/services/translation-group'

export default {
  name: 'translation-filter',
  data: () => ({
    category: null,
    group: null,
    keyType: null,

    categories: [],
    groups: [],
  }),
  mounted() {
    this.fetchCategories()
  },
  methods: {
    async fetchCategories() {
      return TranslationGroupService.getCategories()
        .then(res => this.categories = res.data)
    },
    async fetchGroups(category) {
      return TranslationGroupService.getGroups(category)
        .then(res => this.groups = res.data)
    },
  },
  computed: {
    keyTypes() {
      return [
        {
          key: 1,
          text: this.$t('table.translationGroups.keyType.numeric')
        },
        {
          key: 2,
          text: this.$t('table.translationGroups.keyType.text')
        },
      ]
    },
    categoryFilterLabel() {
      return this.$t('table.translationGroups.categoryFilterLabel')
    },
    groupFilterLabel() {
      return this.$t('table.translationGroups.groupFilterLabel')
    },
    keyFilterLabel() {
      return this.$t('table.translationGroups.keyFilterLabel')
    },
  },
  watch: {
    category(val) {
      this.groups = []
      this.fetchGroups(val)
      this.$emit('category', val)
    },
    group(val){
      this.$emit('group', val)
    },
    keyType(val){
      this.$emit('key', val)
    }
  }
}
</script>
