<template>
  <ViewContainer>
    <v-card>
      <v-card-title>
        <v-icon v-text="`mdi-archive-refresh`" left />
        {{ $t("page.applications.title") }}
        <v-spacer />
        <Button
          :loading="loading"
          :disabled="!hasActives"
          @click="update"
          color="success"
        >
          <v-icon left>mdi-cog-refresh</v-icon>
          {{ $t("page.applications.updateLabel") }}
        </Button>
      </v-card-title>
      <v-card-subtitle v-text="$t('page.applications.subtitle')" />
      <v-card-text>
        <v-list subheader>
          <v-list-item :key="`select-all`">
            <v-list-item-action>
              <v-checkbox @click="onAllSelect" v-model="selectAll" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-html="$t('page.applications.selectAll')" />
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item v-for="app in applications" :key="app.id">
            <v-list-item-action>
              <v-checkbox v-model="actives[app.id]" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-html="app.name" />
              <v-list-item-subtitle
                v-if="updateResult.find((e) => e.appId == app.id)"
              >
                <span v-if="updateResult.find((e) => e.appId == app.id).success"
                  ><v-icon left color="success" small>mdi-check-circle</v-icon>
                  {{ $t("page.applications.updateSuccess") }}</span
                >
                <span v-else
                  ><v-icon left color="error" small>mdi-alert-circle</v-icon>
                  {{ $t("page.applications.updateError") }}</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-start">
        <Button
          :loading="loading"
          :disabled="!hasActives"
          @click="update"
          color="success"
        >
          <v-icon left>mdi-cog-refresh</v-icon>
          {{ $t("page.applications.updateLabel") }}
        </Button>
      </v-card-actions>
    </v-card>
  </ViewContainer>
</template>

<script>
import ApplicationService from '@/services/applications'

export default {
  data: () => ({
    actives: {},
    applications: [],
    loading: false,
    selectAll: false,
    updateResponse: null
  }),
  mounted() {
    this.fetchApplications()
  },
  methods: {
    onAllSelect() {
      this.actives = []
      if (this.selectAll) {
        this.applications.forEach(e => this.actives[e.id] = true)
      }
    },
    update() {
      this.loading = true
      ApplicationService.syncApps({ apps: this.activeArr })
        .finally(() => this.loading = false)
        .then(response => this.updateResponse = response.data)
        .then(() => this.showSuccess(this.$t('message.appSyncSuccess')))
        .catch(error => this.showError(this.$t(error.response.data.error)))
    },
    async fetchApplications() {
      return ApplicationService.query()
        .then(response => this.applications = response.data)
        .catch(error => console.log(error))
    },
  },
  computed: {
    updateResult() {
      return this.updateResponse ? this.updateResponse : []
    },
    activeArr() {
      let list = []
      Object.keys(this.actives).forEach(a => {
        if (this.actives[a]) {
          list.push(a)
        }
      })
      return list
    },
    hasActives() {
      return this.activeArr.length > 0
    }
  }
}
</script>
