<template>
  <SelectField
    :items="languages"
    :label="langFilterLabel"
    v-model="langFilter"
    prepend-inner-icon="mdi-flag"
    item-text="name"
    item-value="lang"
    return-object
    @change="onChange"
    solo
    flat
    dense
    multiple
    outlined
    hide-details
  >
    <template
      v-slot:selection="{ item, index }"
      v-if="compact && langFilter.length > 2"
    >
      <Chip v-if="index === 0" dense>
        <span>{{ item.lang }}</span>
      </Chip>
      <span v-if="index === 1" class="grey--text text-caption">
        (+{{ langFilter.length - 1 }} {{ $t("ui.languageSelector.others") }})
      </span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item ripple @click="selectAllLanguages" v-if="selectAll">
        <v-list-item-action>
          <v-icon :color="langFilter.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="$t('ui.languageSelector.selectAll')" />
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" v-if="selectAll"></v-divider>
    </template>
  </SelectField>
</template>

<script>
import LanguageService from '@/services/languages'

export default {
  name: 'language-selector',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    compact: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    langFilter: [],
    languages: []
  }),
  mounted() {
    this.langFilter = this.value
    this.fetchLanguages()
  },
  methods: {
    onChange() {
      this.$emit('input', this.langFilter)
    },
    selectAllLanguages() {
      this.$nextTick(() => {
        if (this.allLangsSelected) {
          this.langFilter = []
        } else {
          this.langFilter = this.languages.map(e => e.lang).slice()
        }
        this.$emit('input', this.langFilter)
      })
    },
    async fetchLanguages() {
      return LanguageService.query()
        .then(response => this.languages = response.data)
        .catch(error => console.log(error))
    },
  },
  computed: {
    allLangsSelected() {
      return this.langFilter.length == this.languages.length
    },
    langFilterLabel() {
      return this.$t('ui.languageSelector.label')
    },
    icon() {
      if (this.allLangsSelected) return 'mdi-close-box'
      if (this.langFilter.length > 0) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  }
}
</script>
