import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/Login.vue'
import ApplicationPage from '@/views/UpdateApplications.vue'
import TranslationPage from '@/views/Translations/index.vue'
import TranslationGroupsPage from '@/views/TranslationGroups/index.vue'
import NotFound from '@/views/404.vue'

import { store } from '@/store'
import { CHECK_AUTH } from '@/store/actions.type'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/translations',
    alias: '/',
    name: 'translations',
    component: TranslationPage
  },
  {
    path: '/translation-groups',
    name: 'translation-groups',
    component: TranslationGroupsPage
  },
  {
    path: '/applications',
    name: 'applications',
    component: ApplicationPage
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    async beforeEnter(to, from, next) {
      if (store.getters.isAuthenticated) {
        next({ name: 'translations' })
      } else {
        next()
      }
    },
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login') {
    store.dispatch(CHECK_AUTH, { instance: router.app })
      .then(next)
      .catch(() =>
        router.push({ name: 'login' })
          .catch((e) => console.error(e))
      )
  } else {
    next()
  }
})

export default router
