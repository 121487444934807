<template>
  <ViewContainer>
    <v-card>
      <v-card-title>
        <v-icon v-text="`mdi-view-dashboard`" left />
        {{ $t("page.translationGroups.title") }}
      </v-card-title>
      <v-card-text>
        <TranslationGroupTable
          ref="table"
          @translationCreate="onTranslationCreate"
          @editTranslation="editItem"
        />
      </v-card-text>
      <v-dialog max-width="90%" v-model="createDialog">
        <CreateTranslation
          @created="onCreated"
          :initCategory="category"
          :initGroup="group"
          @close="createDialog = false"
        />
      </v-dialog>
      <v-dialog max-width="90%" v-model="editDialog">
        <EditTranslation
          @updated="onUpdated"
          :translationId="editTranslation"
          @close="editDialog = false"
        />
      </v-dialog>
    </v-card>
  </ViewContainer>
</template>

<script>
import Mainbus from '@/mainbus'
import TranslationGroupTable from '@/components/Table/TranslationGroup'
import CreateTranslation from './Create.vue'
import EditTranslation from './Edit.vue'

export default {
  name: 'page-translations',
  components: { TranslationGroupTable, CreateTranslation, EditTranslation },
  data: () => ({
    createDialog: false,
    editDialog: false,
    editTranslation: null,
    category: null,
    group: null,
  }),
  methods: {
    onTranslationCreate() {
      this.createDialog = true
      this.category = this.$refs.table.category
      this.group = this.$refs.table.group
    },
    onCreated(translation) {
      this.createDialog = false;
      this.$refs.table.fetchTranslations()
    },
    editItem(translation) {
      this.editTranslation = translation.id;
      this.editDialog = true;
    },
    onUpdated(translation) {
      this.editDialog = false;
      this.editTranslation = null;
      this.$refs.table.fetchTranslations()
    }
  }
}
</script>
