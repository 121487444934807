export default {
  props: {
    initial: Object,
    errors: {
      type: Object,
      required: false,
      default: null
    },
    submitButtonText: {
      type: String,
      required: false,
      default: 'form.button.create'
    }
  },
  data: () => ({
    valid: true,
    loading: false,
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation()
      Object.assign(this.$data, this.$options.data.call(this));
    },
    validate() {
      return this.$refs.form.validate()
    },
  },
  computed: {
    rules() {
      return {
        required: [
          v => !!v || this.$t('form.validator.required'),
        ],
        requiredSelect: [
          v => !!v || v === 0 || this.$t('form.validator.required'),
        ],
        email: [
          v => /.+@.+\..+/.test(v) || this.$t('form.validator.emailFormat'),
        ],
        requiredEmail: [
          v => !!v || this.$t('form.validator.required'),
          v => /.+@.+\..+/.test(v) || this.$t('form.validator.emailFormat'),
        ],
        passwordConfirmation: [
          v => !!v || this.$t('form.validator.passwordConfirmation'),
        ],
      };
    },
    submitBtnLabel() {
      return this.$t(this.submitButtonText)
    }
  },
}
