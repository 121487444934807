<template>
  <v-menu left bottom>
    <template v-slot:activator="{ on, attrs }">
      <Button icon v-on="on" v-bind="attrs">
        <v-icon v-text="'mdi-flag-variant'" />
      </Button>
    </template>

    <v-list dense>
      <v-subheader
        v-text="$t('app.languageMenu.changeLanguage')"
        class="text-subtitle-1"
      />
      <v-list-item
        v-for="l in languages"
        :key="`lang-${l.lang}`"
        @click="changeLanguage(l.lang)"
      >
        <v-list-item-avatar>
          <country-flag :country="getFlagCode(l.lang)" size="normal" />
        </v-list-item-avatar>
        <v-list-item-title>{{ l.name }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon v-if="$i18n.locale === l.lang" v-text="'mdi-check-bold'" />
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import FlagsMixin from '@/mixins/flags'
import { LangSwitcher } from '@huesgesone/idp-npm-packages'
import LanguageService from '@/services/languages'

export default {
  name: 'app-language-menu',
  mixins: [FlagsMixin],
  data: () => ({
    languages: []
  }),
  mounted() {
    this.fetchLanguages()
  },
  methods: {
    changeLanguage(value) {
      //console.log(this.$i18n.locale)
      LangSwitcher.loadMessages(this.$i18n, value)
      this.$i18n.locale = value
      console.log(this.$i18n.locale)
      this.showSuccess(this.$t('message.languageChanged'))
    },
    async fetchLanguages() {
      return LanguageService.query()
        .then(response => this.languages = response.data)
        .catch(error => console.log(error))
    },
  },
}
</script>
