export default {
  login: {
    emailLabel: 'E-Mail',
    passwordLabel: 'Password',
    loginButton: 'Login'
  },
  validator: {
    required: 'This field is required'
  },
  translation: {
    keyLabel: 'Key'
  },
  translationGroup: {
    categoryLabel: 'Category',
    groupLabel: 'Group',
  },
  import:{
    uploadFieldLabel:'Translation file',
    errors:{
      title: 'Import Errors',
      subtitle:'Please fix the listed errors and try again',
      idRow:'Problem in Row',
      descRow:'Description'
    },
    key:{
      unique: 'Key column needs to be unique.'
    },
    field:{
      unique:'Field column needs to be unique.',
      required:'Field column needs to be present.'
    }
  },
}
