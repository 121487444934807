<template>
  <v-form
    ref="form"
    @submit.prevent="onSubmit"
    :errors="errors"
    v-model="valid"
  >
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-scroll-y-transition>
            <v-col cols="12" v-if="errors">
              <v-card outlined>
                <v-card-title>
                  <v-icon color="error" left>mdi-alert</v-icon>
                  {{ $t("form.import.errors.title") }}
                </v-card-title>
                <v-card-subtitle v-text="$t('form.import.errors.subtitle')" />
                <v-simple-table> </v-simple-table>
              </v-card>
            </v-col>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/form";

export default {
  name: "sqliteUpdate-form",
  mixins: [FormMixin],
  components: {},
  data: () => ({}),
  mounted() {},
  methods: {
    clear() {
      this.errors = null;
    },
    onFileInput(file) {},
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.translation);
      }
    },
  },
  computed: {},
};
</script>
