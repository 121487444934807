<template>
  <v-card flat tile>
    <v-card-title>{{ $t("page.translationGroups.create.title") }}</v-card-title>
    <v-card-subtitle>{{
      $t("translationGroups.create.subtitle")
    }}</v-card-subtitle>
    <v-card-text>
      <TranslationGroupForm
        ref="form"
        :errors="errors"
        @submit="save"
        :loading="loading"
        :initCategory="initCategory"
        :initGroup="initGroup"
      />
    </v-card-text>
    <v-card-actions class="justify-end">
      <Button
        @click="save"
        color="success"
        v-text="$t('dialog.translationGroups.createLabel')"
      />
      <Button
        text
        @click="$emit('close')"
        v-text="$t('dialog.closeDialogLabel')"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import Mainbus from '@/mainbus'
import TranslationGroupForm from '@/components/Form/TranslationGroup'
import TranslationGroupService from '@/services/translation-group'

export default {
  name: 'create-translation-group',
  props: ['initCategory', 'initGroup'],
  components: {
    TranslationGroupForm,
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        const translation = this.$refs.form.translation
        TranslationGroupService.create(translation)
          .then(() => this.showSuccess(this.$t('translationGroups.createSuccess')))
          .then(() => this.errors = null)
          .then(() => this.$emit('refresh'))
          .then(() => this.$emit('close'))
          .then(() => this.$refs.form.clear())
          .catch(error => this.errors = error.response.data)
          .catch(() => this.showError(this.$t('message.saveTranslationFail')))
      }
    },
  },
  data: () => ({
    loading: false,
    errors: null,
  })
}
</script>
