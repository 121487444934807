<template>
  <v-card>
    <v-card-title>{{ $t("dialog.import.title") }}</v-card-title>
    <v-card-subtitle>{{ $t("dialog.import.subtitle") }}</v-card-subtitle>
    <v-card-text>
      <ImportForm
        :errors="errors"
        @submit="save"
        :loading="loading"
        ref="form"
      />
    </v-card-text>
    <v-card-actions class="justify-end">
      <Button
        @click="save"
        color="success"
        v-text="$t('dialog.import.importLabel')"
      />
      <Button
        text
        @click="$emit('close')"
        v-text="$t('dialog.closeDialogLabel')"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import TranslationService from '@/services/translation'
import ImportForm from '@/components/Form/Import.vue'
import Mainbus from '@/mainbus'

export default {
  name: 'page-import',
  components: { ImportForm },
  data: () => ({
    loading: false,
    errors: null
  }),
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        const f = this.$refs.form.uploadFile
        TranslationService.import(f)
          .finally(() => this.loading = false)
          .then(() => this.$refs.form.reset())
          .then(() => this.showSuccess(this.$t('message.importSuccess')))
          .then(() => this.$refs.form.clear())
          .then(() => Mainbus.$emit('refresh'))
          .then(() => this.$emit('close'))
          .catch(errors => this.errors = errors.response.data)
      }
    },
  }
}
</script>
