<template>
  <v-card flat tile>
    <v-card-title>{{ $t("page.translation.create.title") }}</v-card-title>
    <v-card-subtitle>{{ $t("page.translation.create.subtitle") }}</v-card-subtitle>
    <v-card-text>
      <TranslationForm
        ref="form"
        :errors="errors"
        @submit="save"
        :loading="loading"
      />
    </v-card-text>
    <v-card-actions class="justify-end">
      <Button
        @click="save"
        color="success"
        v-text="$t('dialog.translation.createLabel')"
      />
      <Button
        text
        @click="$emit('close')"
        v-text="$t('dialog.closeDialogLabel')"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import Mainbus from '@/mainbus'
import TranslationForm from '@/components/Form/Translation'
import TranslationService from '@/services/translation'

export default {
  components: {
    TranslationForm,
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        const translation = this.$refs.form.translation
        TranslationService.create(translation)
          .then(() => this.showSuccess(this.$t('translation.createSuccess')))
          .then(() => this.$emit('created'))
          .then(() => this.$refs.form.clear())
          .catch(errors => this.errors = errors.response.data)
      }
    },
  },
  data: () => ({
    loading: false,
    errors: null,
  })
}
</script>
