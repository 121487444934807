export default {
  translation: {
    title: 'Translations',
    translateTooltip:'Google Translate',
    edit:{
      title:'Edit translation',
      subtitle:''
    },
    create:{
      title:'Create translation',
      subtitle:''
    }
  },
  translationGroups: {
    title: 'Translation groups',
    edit:{
      title:'Edit translation group',
      subtitle:''
    },
    create:{
      title:'Create translation group',
      subtitle:''
    }
  },
  applications: {
    title: 'Update applications',
    subtitle: 'Select wich applications should be synced.',
    updateLabel: 'Update',
    selectAll:'Select all',
    updateSuccess:'Update successful',
    updateError:'Update failed'
  }
}
