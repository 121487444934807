<template>
  <v-app-bar app>
    <v-app-bar-nav-icon @click="$emit('toggleSidebar')" />

    <v-spacer />

    <UserMenu />
    <LanguageMenu />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

import UserMenu from './UserMenu'
import LanguageMenu from './LanguageMenu'

export default {
  name: 'appbar',
  components: {
    UserMenu,
    LanguageMenu,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isAdmin']),
  },
}
</script>
