import CountryFlag from 'vue-country-flag'

export default {
  components: { CountryFlag },
  methods: {
    getFlagCode(lang) {
      switch (lang) {
        case 'en':
          return 'gb'
        case 'da':
          return 'dk'
        case 'nl_be':
          return 'be'
        case 'cs':
          return 'cz'
        case 'el':
          return 'gr'
        default:
          return lang
      }
    },
  }
}
