<template>
  <v-card>
    <v-card-title>{{ $t("dialog.sqliteUpdate.title") }}</v-card-title>
    <v-card-subtitle>{{ $t("dialog.sqliteUpdate.subtitle") }}</v-card-subtitle>
    <v-card-text>
      <SqliteForm
        :errors="errors"
        @submit="save"
        :loading="loading"
        ref="form"
      />
    </v-card-text>
    <v-card-actions class="justify-end">
      <Button
        :loading="loading"
        @click="save"
        color="success"
        v-text="$t('dialog.sqliteUpdate.importLabel')"
      />
      <Button
        text
        @click="$emit('close')"
        v-text="$t('dialog.closeDialogLabel')"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import TranslationService from "@/services/translation";
import SqliteForm from "@/components/Form/sqliteUpdate.vue";
import Mainbus from "@/mainbus";
import SqliteUpdateService from "@/services/sqlite-update";

export default {
  name: "page-sqliteUpdate",
  components: { SqliteForm },
  data: () => ({
    loading: false,
    errors: null,
  }),
  methods: {
    save() {
      this.loading = true;
      SqliteUpdateService.update()
        .finally(() => (this.loading = false))
        .then((response) => (this.updateResponse = response.data))
        .then(() => this.showSuccess(this.$t("message.sqliteUpdateSuccess")))
        .catch((error) => this.showError(this.$t(error.response.data.error)));
    },
  },
};
</script>
