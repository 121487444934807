export default {
  refreshTooltip: 'Refresh table',
  massDeleteTranslationTooltip: 'Mass delete translations',
  massDatabaseExportTooltip: 'Export selected translations',
  massDelete: 'Mass delete',
  massExport: 'Export',

  translation: {
    key: 'Key',
    updated_at: 'Last change',
    actions: 'Aktionen',
    category: 'Category',
    group: 'Group',
    translationSearchLabel: 'Search...',
    deleteTranslationTooltip: 'Delete translation',
    editTranslationTooltip: 'Edit translation',
    deleteConfirm: 'Are you sure you want to delete this translation?',
    massDeleteConfirm:'Are you sure you want to delete the selected translation keys?',
    createTooltip: 'Create translation',
  },
  translationGroups: {
    categoryFilterLabel: 'Category filter',
    groupFilterLabel: 'Group filter',
    keyFilterLabel: 'Key filter',

    keyType: {
      numeric: 'Numeric',
      text: 'Text'
    }
  }
}
