export default {
  props: {
   /* search: {
      type: String,
      required: false,
      default: ''
    }*/
  },
  data: () => ({
    loading: false,
    options: {},
    numTotal: 0,
  })
}
